import { AFASApplicationConfig } from '../afas/afas-application';
import { AFASApplication } from '../podium';

export function ifConfigMiddleware<R extends AFASApplication | AFASApplicationConfig>(
  middleware: (config: AFASApplicationConfig) => Promise<R>
): (appOrConfig: AFASApplication | AFASApplicationConfig) => Promise<R> {
  return async (appOrConfig): Promise<R> => {
    if (isConfig(appOrConfig)) {
      return middleware(appOrConfig);
    }

    return appOrConfig as R;
  };
}

function isConfig(appOrConfig: AFASApplication | AFASApplicationConfig): appOrConfig is AFASApplicationConfig {
  return 'appAssetsBaseUrl' in appOrConfig;
}
