export const METRIC_APP_INIT = 'app-init';
export const METRIC_APP_STARTED = 'app-started';

export const METRIC_COMPOSITE_PARSE_START = 'composite-parse-start';
export const METRIC_COMPOSITE_PARSE_END = 'composite-parse-end';

export const METRIC_COMPOSER_START = 'composer-start';
export const METRIC_COMPOSER_END = 'composer-end';

export const METRIC_CONTROLLER_INIT_START = 'controller-init-start';
export const METRIC_CONTROLLER_INIT_END = 'controller-init-end';

export const METRIC_LOAD_RESULT = 'load-result';
export const METRIC_ASYNC_PAGE_RESULT = 'async-page-result';

export const METRIC_SHOW_PAGE_START = 'show-page-start';
export const METRIC_SHOW_PAGE_END = 'show-page-end';
